:root {
  --software-proficiencies: #646464;
}

/* HEADER AUX */

.header-auxiliary {
  display: flex;
  flex: 0 1 50%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: var(--header-padding);
  font-weight: 400;
  padding-right: var(--header-half-outer-padding);
  padding-left: var(--header-half-inner-padding);
}

.auxiliary-label {
  font-size: 0.75rem;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin: 0 0 1.5rem 0;
}

.recent-experience {
  width: 34vw;
  max-width: 30rem;
  margin-top: 3rem;
}

.experiences {
  display: unset;
}

.experience {
  display: inline-block;
  margin: auto;
  margin-bottom: 3rem;
  min-width: 10rem;
}

.experience:first-child {
  margin-left: 0;
}

.experience:last-child {
  margin-right: 0;
}

.experience-graphic {
  margin-bottom: 1.25rem;
  max-width: 3rem;
  min-width: 0;
}

.experience-position {
  font-size: 0.875rem;
  text-align: center;
  margin-bottom: 0.5rem;
}

.experience-workplace {
  color: var(--header-link);
  font-size: 0.75rem;
  text-align: center;
}

.software-proficiencies {
  max-width: 30rem;
  margin-bottom: 2rem;
}

.proficiencies {
  color: var(--software-proficiencies);
  font-size: 0.75rem;
  font-weight: 300;
}

.proficiency {
  display: inline-block;
  margin: 0 0.375rem 1rem 0.375rem;
}

.proficiency:first-child {
  margin-top: 0;
  margin-left: 0;
}

.proficiency:last-child {
  margin-bottom: 0;
  margin-right: 0;
}

/* MEDIA QUERIES */

@media only screen and (min-width: 1080px) {}

@media only screen and (max-width: 1080px) {
  .software-proficiencies {
    display: none;
  }
  .recent-experience {
    width: unset;
  }
}

@media only screen and (max-width: 1439px) and (max-height: 900px) {
  .software-proficiencies {
    display: none;
  }
}

@media only screen and (max-width: 1440px) and (max-height: 768px) {
  .software-proficiencies {
    display: none;
  }
  .experience {
    min-width: 10rem;
  }
  .experience-graphic {
    margin-bottom: 0.5rem;
    max-width: 2rem;
  }
}

@media only screen and (max-width: 1080px) and (max-height: 720px) {
  .experience {
    min-width: 10rem;
  }
  .experience-graphic {
    margin-bottom: 0.5rem;
    max-width: 2rem;
  }
}

@media only screen and (max-width: 720px) {
  .header-auxiliary {
    padding-left: var(--header-padding-mobile);
    padding-right: var(--header-padding-mobile);
    padding-bottom: 2.5rem;
  }
  .auxiliary-label {
    margin-bottom: 2rem;
  }
  .recent-experience {
    width: 90vw;
    max-width: unset;
  }
  .experiences {
    margin-bottom: 3rem;
  }
  .experience {
    min-width: 10rem;
  }
  .experience-graphic {
    margin-bottom: 0.5rem;
    max-width: 2rem;
  }
  .experience:last-child {
    margin-bottom: 3rem;
  }
  ;
}

@media only screen and (max-width: 600px) {
  .recent-experience {
    display: none;
  }
  .experience {
    min-width: 8rem;
  }
  .experience-position {
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }
  .experience-workplace {
    font-size: 0.625rem;
  }
  .experience-graphic {
    margin-bottom: 0.375rem;
    max-width: 1.5rem;
  }
  .experience:last-child {
    margin-bottom: 0rem;
  }
  ;
}

@media only screen and (min-width: 1400px) and (max-height: 600px) {
  .software-proficiencies {
    display: none;
  }
}

@media only screen and (max-width: 1400px) and (max-height: 540px) {
  .software-proficiencies {
    display: none;
  }
}

@media only screen and (max-height: 540px) {
  .recent-experience {
    display: none;
  }
  .software-proficiencies {
    display: none;
  }
}

@media only screen and (min-width: 2250px) {
  .header-auxiliary {
    padding-right: var(--header-half-outer-padding-xl);
    padding-left: var(--header-half-inner-padding-xl);
  }
  .auxiliary-label {
    font-size: 1rem;
    letter-spacing: 0.08em;
    margin: 0 0 2.5rem 0;
  }
  .recent-experience {
    width: 34vw;
    max-width: 50rem;
    margin-top: 3rem;
  }
  .experience{
    min-width: 16rem;
    margin-bottom: 4rem;
  }
  .experience-graphic {
    margin-bottom: 1rem;
    min-width: 3.5rem;
  }
  .experience-position {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
  .experience-workplace {
    font-size: 1.125rem;
  }
  .software-proficiencies {
    max-width: 50rem;
    margin-bottom: 3rem;
  }
  .proficiencies {
    font-size: 1rem;
  }
  .proficiency{
    margin: 0 0.75rem 1.25rem 0.75rem;
  }
}