  :root {
    --bio-description-mobile: 1.25rem;
    --bio-description: 1.5rem;
    --bio-description-xl: 2rem;
  }

  .header-bio {
    color: var(--header-text);
    display: flex;
    flex: 0 1 50%;
    flex-direction: column;
    justify-content: left;
    text-align: left;
    padding: var(--header-padding);
    font-weight: 400;
    padding-right: var(--header-half-inner-padding);
    padding-left: var(--header-half-outer-padding);
  }

  .bio-label {
    font-size: 3.75rem;
    text-align: left;
    margin: 0;
    font-weight: 400;
  }

  .bio-description {
    text-align: left;
    font-size: var(--bio-description);
    margin: 2rem 0 1rem 0;
  }

  .bio-contact {
    margin-top: 1rem;
    font-size: 1.25rem;
  }

  .bio-contact-link {
    color: var(--header-email-link);
    text-decoration: none;
    transition: color .15s;
  }

  .bio-contact-link:hover {
    color: var(--header-email-link-hover);
  }

  .bio-contact.injury {
    font-weight: 400;
    color: rgb(145, 41, 55);
  }



  /* MEDIA QUERIES */

  @media only screen and (min-width: 1080px) {}

  @media only screen and (max-width: 1080px) {}

  @media only screen and (max-width: 1200px) {
    .bio-label {
      font-size: 2.5rem;
      text-align: center;
    }

    .bio-description {
      font-size: var(--bio-description-mobile);
      text-align: center;
      margin: 1.5rem 0 1rem 0;
    }

    .bio-contact {
      text-align: center;
      display: flex;
      flex-direction: column;
    }
  }

  @media only screen and (max-width: 1400px) and (max-height: 900px) {
    .bio-label {
      font-size: 2.5rem;
      text-align: center;
    }

    .bio-description {
      font-size: var(--bio-description-mobile);
      text-align: center;
      margin: 1.5rem 0 1rem 0;
    }

    .bio-contact {
      text-align: center;
      display: flex;
      flex-direction: column;
    }
  }

  @media only screen and (max-width: 1080px) and (max-height: 720px) {}

  @media only screen and (max-width: 720px) {
    .header-bio {
      padding-left: var(--header-padding-mobile);
      padding-right: var(--header-padding-mobile);
    }

    .bio-label {
      margin-top: 4vw;
    }

    .bio-description {
      margin-bottom: 2rem;
    }

    .bio-contact {
      font-size: 1.1rem;
      text-align: center;
      margin-top: 0;
    }
  }

  @media only screen and (max-height: 540px) {
    .header-bio {
      padding-left: var(--header-padding-mobile);
      padding-right: var(--header-padding-mobile);
    }

    .bio-label {
      font-size: 2.5rem;
      text-align: center;
    }

    .bio-description {
      font-size: var(--bio-description-mobile);
      text-align: center;
      margin: 1.5rem 0 1rem 0;
    }

    .bio-contact {
      text-align: center;
      display: flex;
      flex-direction: column;
    }
  }

  @media only screen and (max-height: 600px) {
    .bio-label {
      font-size: 2.5rem;
      text-align: center;
    }

    .bio-description {
      font-size: var(--bio-description-mobile);
      text-align: center;
      margin: 1.5rem 0 1rem 0;
      align-self: center;
    }

    .bio-contact {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  @media only screen and (min-width: 2250px) {

    .header-bio {
      padding-right: var(--header-half-inner-padding-xl);
      padding-left: var(--header-half-outer-padding-xl);
    }

    .bio-label {
      font-size: 5.25rem;
    }

    .bio-description {
      font-size: var(--bio-description-xl);
      margin-bottom: 1.5rem;
    }

    .bio-contact {
      font-size: 1.8375rem;
    }
  }

  @media only screen and (min-width: 4096px) {

    .header-bio {
      text-align: center !important;
    }

    .bio-label {
      text-align: center !important;
      max-width: var(--header-half-content-max-width-xl);
    }

    .bio-description {
      text-align: center !important;
    }

    .bio-contact {
      font-size: 1.8375rem;
      max-width: var(--header-half-content-max-width-xl);
    }
  }