/* Flickity CSS */

/* Flickity ends here */

.gallery {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.carousel {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60vh;
    max-height: 80vh;
}

.carousel-cell {
    width: 100%;
    height: 100%;
}

.cell-to-fullscreen {
    position: absolute;
    bottom: var(--overlay-inner-padding-mobile);
    right: var(--overlay-inner-padding-mobile);
    color: var(--overlay-interactive-primary);
}

.cell-content {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.cell-content.desktop-contain {
    object-fit: contain;
}

.carousel-nav {
    height: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1.75rem;
    align-self: center;
    width: 100%;
    z-index: 2;
}

.carousel-nav-direction {
    color: var(--overlay-interactive-primary);
    transition: all .15s ease;
    margin: 0 0 0 var(--overlay-inner-padding-mobile);
    /* anti-button styles */
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.carousel-nav-direction.forward {
    float: right;
    margin: 0 var(--overlay-inner-padding-mobile) 0 0;
}

.carousel-nav-direction:hover {
    color: white;
    /* anti-button styles */
    outline: none;
    box-shadow: none;
}

.carousel-nav-position {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
}

.carousel-nav-position-dot {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: white;
    transition: all .15s ease;
    /* anti-button styles */
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.carousel-nav-position-dot:hover {
    transform: scale(1.75);
    color: var(--overlay-interactive-primary);
    /* anti-button styles */
    outline: none;
    box-shadow: none;
}

.carousel-nav-position-dot.selected {
    color: white;
    transform: scale(1.75);
}

.carousel-nav-position-dot-icon {
    width: 0.3rem !important;
    height: 100% !important;
}

@media only screen and (max-width: 1080px) {
    .carousel {
        height: 60vh;
        max-height: 540px;
    }
    .carousel-nav.single {
        margin-bottom: -0.5rem;
    }
    .carousel-nav-position-dot {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
    }
    .cell-content.desktop-contain {
        object-fit: cover;
    }
    .cell-content.mobile-contain {
        object-fit: contain;
    }
}

@media only screen and (min-width: 2250px) {
    .gallery {
        max-width: var(--header-half-content-max-width-xl);
    }
    .carousel-nav {
        height: 2.5rem;
        margin-top: 3rem;
    }
    .carousel-nav-position-dot {
        padding-left: 0.875rem;
        padding-right: 0.875rem;
    }
    .carousel-nav-position-dot-icon {
        width: .4rem !important;
        height: 100% !important;
    }
    .carousel-nav-direction-icon {
        width: 2rem !important;
        height: 100% !important;
    }
}