:root {
    --overlay-close-text: 1rem;
    --overlay-close-text-xl: 1.625rem;
    --overlay-close-icon-mobile: 1.5rem;
    --overlay-close-icon: 2rem;
    --overlay-close-icon-xl: 2.25rem;
}

.overlay-close {
    color: var(--overlay-interactive-primary);
    position: absolute;
    top: var(--overlay-close-icon-offset);
    left: var(--overlay-close-icon-offset);
    z-index: 2;
    transition: color .15s;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.overlay-close:hover {
    color: white;
    transition: color .15s;
}

.overlay-close-icon {
    width: var(--overlay-close-icon) !important;
    height: var(--overlay-close-icon) !important;
}

.overlay-close-text {
    font-size: var(--overlay-close-text);
    color: white !important;
    text-transform: uppercase;
    letter-spacing: 0.14em;
    margin: 0 1rem 0 0.75rem;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 1200px) {
    .overlay-close {
        top: var(--overlay-close-icon-offset-mobile);
        left: var(--overlay-close-icon-offset-mobile);
    }
}

@media only screen and (max-width: 1080px) {
    .overlay-close {
        top: var(--overlay-close-icon-offset-mobile);
        left: unset;
        right: var(--overlay-close-icon-offset-mobile);
    }
    .overlay-close-icon {
        width: var(--overlay-close-icon-mobile) !important;
        height: var(--overlay-close-icon-mobile) !important;
    }

    .overlay-close-text {
        display: none;
    }
}



@media only screen and (min-width: 2250px) {
    .overlay-close-text {
        font-size: var(--overlay-close-text-xl);
    }
    .overlay-close {
        top: var(--overlay-close-icon-offset-xl);
        left: var(--overlay-close-icon-offset-xl);
    }
    .overlay-close-icon {
        width: var(--overlay-close-icon-xl) !important;
        height: var(--overlay-close-icon-xl) !important;
    }
}