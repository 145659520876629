:root {
    --overlay-nav-link-text: 0.625rem;
    --overlay-nav-link-text-xl: 1.125em;

    --overlay-nav-icon-xl: 1.75rem;
}

.overlay-nav {
    padding: var(--overlay-inner-padding) var(--overlay-outer-padding) var(--overlay-outer-padding) var(--overlay-outer-padding);
    margin-top: auto;
}

.overlay-nav-link {
    color: var(--overlay-interactive-primary);
    font-size: var(--overlay-nav-link-text);
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.16em;
    transition: color .15s;
    text-decoration: none;
    margin-left: 1rem;
}

.overlay-nav-link:hover {
    color: white;
}

.overlay-nav-link.forward {
    float: right;
    margin-left: 0;
    margin-right: 1rem;
}

.overlay-nav-icon {
    margin: 0 1rem -0.5rem 0;
}

.overlay-nav-icon.forward {
    margin: 0 0 -0.5rem 1rem;
}

.overlay-nav-icon.forward-mobile {
    display: none;
    margin: 0 1rem -0.5rem 0;
}

.overlay-nav-text {
    color: white !important;
    opacity: 1;
    animation: fadeIn ease-in 1;
    animation-duration: 0.04s;
}

.overlay-nav-text.transparent {
    opacity: 0;
    animation: fadeOut ease-in 1;
    animation-duration: 0.18s;
}

.overlay-close-mobile {
    display: none;
    color: var(--overlay-interactive-primary);
    align-self: center;
    align-items: center;
    justify-content: center;
    transition: color 0.15s;
    text-decoration: none;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.overlay-close-mobile:hover {
    color: white;
}

.overlay-close-text-mobile {
    color: white !important;
    text-transform: uppercase;
    letter-spacing: 0.16em;
    margin: 0 1rem 0 0.5rem;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 1200px) {
    .overlay-nav {
        padding: var(--overlay-inner-padding-mobile) var(--overlay-outer-padding-mobile) var(--overlay-outer-padding-mobile) var(--overlay-outer -padding-mobile);
    }
}

@media only screen and (max-width: 1080px) {
    .overlay-close-mobile {
        display: flex;
    }
    .overlay-nav {
        padding: var(--overlay-inner-padding-mobile) var(--overlay-outer-padding-mobile) var(--overlay-outer-padding-mobile) var(--overlay-outer-padding-mobile);
    }
    .overlay-nav-link {
        margin-left: 0;
    }
    .overlay-nav-link.forward {
        margin-right: 0;
    }
}

/* Overlay Nav Row to Column */

@media only screen and (max-width: 600px) {
    .overlay-nav {
        display: flex;
        flex-direction: column;
    }
    .overlay-nav-link {
        line-height: 1.25rem;
    }
    .overlay-nav-link.forward {
        float: none;
        margin-top: 1.5rem;
    }
    .overlay-nav-icon.forward {
        display: none;
    }
    .overlay-nav-icon.forward-mobile {
        display: unset;
    }
    .overlay-close-mobile {
        margin-top: 4rem;
    }
}

@media only screen and (min-width: 2250px) {
    .overlay-nav{
        padding: var(--overlay-inner-padding-xl) var(--overlay-outer-padding-xl) var(--overlay-outer-padding-xl) var(--overlay-outer-padding-xl);

        
    }

    .overlay-nav-link {
        font-size: var(--overlay-nav-link-text-xl);
        
    }

    .overlay-nav-icon {
        width: var(--overlay-nav-icon-xl) !important;
        height: var(--overlay-nav-icon-xl) !important;
    }
    
}