:root {
    --tile-overlay : rgba(53, 53, 58,0.7); /* delete me*/


    --tile-width: 12.5vw; /* 10vw for multiples of 10; 12.5vw for 8 – don't go larger than this. Also, this must change with the set standardTileRowCount in the projects data */
    --tile-min-width: 128px;
    --tile-max-width: 480px;

    --tile-width-s: 20vw; /* 16.66vw; 20vw */
    --tile-min-width-s: 128px;
    --tile-max-width-s: 480px;

    --tile-width-xs: 25vw;
    --tile-min-width-xs: 30.68px;
    --tile-max-width-xs: 100vw;

    --tile-width-xxs: 50vw;
    --tile-min-width-xxs: 50vw;
    --tile-max-width-xxs: 100vw;

    /* consider width/height set to fraction of viewport width at different media queries*/
    /* > 879px => 88px (min width) with 10vw regular width */

}

.project-tiles {
    background-color: rgb(30,30,30);


    max-width: 100vw;
    width: 100vw;
    display: flex;

    flex-wrap: wrap;
    flex-grow: 1;

    width: 100%;

    justify-content: flex-start;
    align-content: flex-start;
    overflow: hidden;
    flex: 1;
    

}

.project-tile {
    min-width: var(--tile-min-width);
    max-width: var(--tile-max-width);
    min-height: var(--tile-min-width);
    max-height: var(--tile-max-width);

    width: var(--tile-width);
    height: var(--tile-width);


}

.tile-image {    
    width: 100%;
    height: auto;

    transition: .2s ease;
    filter: grayscale(100%) brightness(100%) contrast(80%);
}




.tile-image:hover {
    filter: grayscale(0%) brightness(100%) contrast(100%);
}


/* MEDIA QUERIES */

@media only screen and (max-width: 1279px) {

    .project-tile {
        min-width: var(--tile-min-width-s);
        max-width: var(--tile-max-width-s);
        min-height: var(--tile-min-width-s);
        max-height: var(--tile-max-width-s);

        width: var(--tile-width-s);
        height: var(--tile-width-s);



    }
}

@media only screen and (max-width: 767px) {

    .project-tile {
        min-width: var(--tile-min-width-xs);
        max-width: var(--tile-max-width-xs);
        min-height: var(--tile-min-width-xs);
        max-height: var(--tile-max-width-xs);

        width: var(--tile-width-xs);
        height: var(--tile-width-xs);
    }
}

@media only screen and (max-width: 599px) {

    .project-tile {
        min-width: var(--tile-min-width-xxs);
        max-width: var(--tile-max-width-xxs);
        min-height: var(--tile-min-width-xxs);
        max-height: var(--tile-max-width-xxs);

        width: var(--tile-width-xxs);
        height: var(--tile-width-xxs);
    }
}