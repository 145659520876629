:root {
  /* header */
  --header-text: #000;
  --header-link: #a08a5d;
  /* d2caaa */
  --header-link-hover: #9c7462;
  --header-email-link: #4c71b6;
  /* #9ac4e6 */
  --header-email-link-hover: #3f7aaa;
  /* #9ac4e6 */
  --header-background: rgb(248, 250, 251);
  /* #14181f with #010102 mrossary */
  --header-divider: #c4c4c4;
  --header-width: 88vw;
  --header-width-mobile: 94vw;
  --header-padding: 0rem 4rem;
  --header-padding-mobile: 2rem;
  --header-half-inner-padding: 4rem;
  --header-half-outer-padding: 4rem;
  --header-half-inner-padding-xl: 8rem;
  --header-half-outer-padding-xl: 8rem;
  --header-half-content-max-width-mobile: 67.5rem;
  --header-half-content-max-width: 45rem;
  --header-half-content-max-width-xl: 67.5rem;
  --header-menu-height: 4rem;
  --header-menu-item: #000;
  --header-menu-item-hover: #646464;
  overflow-x: hidden;
  background-color: var(--header-background);
}

h1, h2, h3, h4, h5, h6 {
  font-size: 1rem;
}

p {
  max-width: var(--header-half-content-max-width);
  margin-top: 0;
}

.header-link {
  color: var(--header-link);
  text-decoration: none;
  transition: color .15s;
}

.header-link:hover {
  color: var(--header-link-hover);
}

.folio-header {
  background-color: var(--header-background);
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex: 90;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.header-container {
  color: var(--header-text);
  display: flex;
  text-align: center;
  font-weight: 400;
  width: var(--header-width);
  flex-direction: row;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.header-divider {
  border-left: 1px solid var(--header-divider);
  min-height: 30rem;
  height: 70vh;
}

/* MEDIA QUERIES */

@media only screen and (min-width: 1080px) {}

@media only screen and (max-width: 1080px) {
  p {
    max-width: var(--header-half-content-max-width-mobile);
  }
}

@media only screen and (max-width: 1200px) and (max-height: 900px) {
  .header-container {
    width: var(--header-width-mobile);
  }
}

@media only screen and (max-width: 1080px) and (max-height: 720px) {}

@media only screen and (max-width: 720px) {
  .header-container {
    padding-top: 1.5rem;
    padding-bottom: 1.5;
    flex-direction: column;
  }
  .header-divider {
    display: none;
  }
}

@media only screen and (max-height: 600px) {
  .header-divider {
    min-height: 20rem;
  }
}

@media only screen and (max-height: 480px) {
  .header-divider {
    min-height: 2rem;
  }
}

@media only screen and (max-height: 540px) {
  .header-container {
    padding-top: 1.5rem;
    padding-bottom: 1.5;
    flex-direction: column;
  }
  .header-divider {
    display: none;
  }
  .header-container {
    padding-top: 1.5rem;
    padding-bottom: 3rem;
    flex-direction: column;
  }
}

@media only screen and (min-width: 2250px) {
  p {
    max-width: var(--header-half-content-max-width-xl);
  }
}