html {
  scroll-behavior: smooth;
  scrollbar-width: thin;

  overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

html::-webkit-scrollbar { /* WebKit (Chrome) */
    width: 0;
    height: 0;
}

a:hover {
  cursor: pointer;
}