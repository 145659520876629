 /* TRANSITION ANIMATIONS */

 @keyframes fadeIn {
   from {
     opacity: 0;
   }
   to {
     opacity: 1;
   }
 }

 @keyframes fadeOut {
   from {
     opacity: 1;
   }
   to {
     opacity: 0;
   }
 }

 /* RANDOMOTRON-PROPER */

 .randomotron {
   opacity: 0;
   animation: fadeOut ease-in 1;
   animation-duration: .3s;
   font-size: 0.875rem;
   font-weight: 500;
   min-height: 3rem;
   max-height: 3rem;
 }

 .randomotron.opaque {
   opacity: 1;
   animation: fadeIn ease-in 1;
   animation-duration: .15s;
 }

 .randomotron-icon {
   font-size: 0.8rem !important;
   width: 0.8rem !important;
   height: 0.8rem !important;
 }

 .link {
   color: var(--header-link);
   text-decoration: none;
   transition: color .15s;
 }

 .link:hover {
   color: var(--header-link-hover);
 }

 /* MEDIA QUERIES */

 @media only screen and (max-width: 1200px) and (max-height: 600px) {
   .randomotron {
     display: none;
   }
 }

 @media only screen and (max-width: 1080px) and (max-height: 600px) {
   .randomotron {
     display: none;
   }
 }

 @media only screen and (max-width: 954px) {
   .randomotron {
     display: none;
   }
 }

 @media only screen and (max-width: 720px) and (min-height: 720px) {
   .randomotron {
     max-width: 80vw;
     margin-top: 0rem;
     display: unset;
   }
 }

 @media only screen and (max-width: 600px) {
   .randomotron {
     margin-top: 3rem;
     display: unset;
   }
 }

 @media only screen and (min-width: 1412px) and (max-height: 480px) {
   .randomotron {
     display: none;
   }
 }

 @media only screen and (max-width: 1920px) and (max-height: 480px) {
   .randomotron {
     display: none;
   }
 }

 @media only screen and (max-height: 540px) {
  .randomotron {
    margin-top: 2rem;
  }
}

 @media only screen and (min-width: 601px) and (min-height: 720px) {
   .randomotron {
     margin-top: .5rem;
   }
 }

 @media only screen and (min-width: 2250px) {
   .randomotron {
     font-size: 1.125rem;
   }
 }

 @media only screen and (min-width: 2250px) and (max-height: 540px){
  .randomotron {
    font-size: 1.5rem;
    margin-top: 4rem;
    margin-bottom: 2rem;

  }
}